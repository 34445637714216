import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useGet } from '../hooks/useGet'
import { usePost } from '../hooks/usePost'
import { useDelete } from '../hooks/useDelete'
import { useConfirm } from '../hooks/useConfirm'
import { Avatar } from '@rmwc/avatar'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadBox } from '../common/RadBox'
import { RadButton } from '../common/RadButton'
import { RadColumnLayout } from '../common/RadColumnLayout'
import { RadContainer } from '../common/RadContainer'
import { RadHeader } from '../common/RadHeader'
import { RadLineBreakRenderer } from '../common/RadLineBreakRenderer'
import { RadLink } from '../common/RadLink'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { formatDate, formatDateTime } from '../common/utilities'

export function ParticipantDetail () {
  const navigate = useNavigate()
  const { participantId } = useParams()
  const [reloadCount, setReloadCount] = useState(0)
  const { data: participant } = useGet(`/api/participant/${participantId}?reloadCount=${reloadCount}`, true)
  const confirmDelete = useConfirm('Delete participant?', 'Delete participant permanently? This action cannot be undone.', 'Delete', () => { remove() })
  const remove = useDelete(`/api/participant/${participantId}`, () => { navigate('/participant') })
  const sendInvitations = usePost('/api/participant/send-invitation', { participantIds: [participantId] }, () => { setReloadCount(reloadCount + 1) })

  function sendInvitation () {
    sendInvitations()
  }

  if (participant != null) {
    return (
      <RadAppLayout
        name={`${participant.lastName}, ${participant.firstName}`}
        contentHeader={
          <RadHeader
            variant='h1'
            actions={
              <RadSpaceBetween direction='horizontal' size='xs'>
                <RadButton onClick={() => navigate('edit')}>Edit</RadButton>
                <RadButton onClick={confirmDelete} disabled={participant.isProtected}>Delete</RadButton>
                <RadButton onClick={sendInvitation}>Send Invitation</RadButton>
              </RadSpaceBetween>
            }
          >
            {`${participant.lastName}, ${participant.firstName}`}
          </RadHeader>
        }
        content={
          <RadSpaceBetween size='l'>
            <RadContainer
              header={
                <RadHeader variant='h2'>
                  Details
                </RadHeader>
              }
            >
              <RadSpaceBetween size='l'>
                <RadColumnLayout columns={3} borders='vertical'>
                  <div>
                    <RadBox variant='awsui-key-label'>
                      Organization
                    </RadBox>
                    <div>{participant.organization ?? '-'}</div>
                  </div>
                  <div>
                    <RadBox variant='awsui-key-label'>
                      Email
                    </RadBox>
                    <div>{participant.email != null ? <RadLink href={'mailto: ' + participant.email}>{participant.email}</RadLink> : '-'}</div>
                  </div>
                  <div>
                    <RadBox variant='awsui-key-label'>
                      Invitation Sent
                    </RadBox>
                    <div>{participant.invitationSentAt != null ? formatDateTime(participant.invitationSentAt) : '-'}</div>
                  </div>
                </RadColumnLayout>
              </RadSpaceBetween>
            </RadContainer>
            <RadContainer
              header={
                <RadHeader variant='h2'>
                  Messages
                </RadHeader>
              }
            >
              <div className='messages'>
                <RadSpaceBetween size='l'>
                  {participant.messages.map(message =>
                    <div key={message.id} className={message.direction}>
                      {message.direction === 'in' &&
                        <Avatar
                          src='../mio.png'
                          size='large'
                          name='MIO'
                          referrerPolicy='no-referrer'
                        />}
                      {message.direction === 'out' &&
                        <Avatar
                          size='large'
                          name={`${participant.firstName} ${participant.lastName}`}
                          referrerPolicy='no-referrer'
                        />}
                      <div className='message'><span><RadLineBreakRenderer text={message.text} /></span></div>
                      <div className='time'>{formatDateTime(message.createdAt).replace(formatDate(Date.now()), '')}</div>
                    </div>
                  )}
                </RadSpaceBetween>
              </div>
              {participant.messages.length === 0 &&
                <RadBox textAlign='center' color='inherit' padding={{ bottom: 'l' }}>
                  No messages found.
                </RadBox>}
            </RadContainer>
          </RadSpaceBetween>
        }
      />
    )
  }
}
