import React, { useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useGet } from '../hooks/useGet'
import { usePost } from '../hooks/usePost'
import { Avatar } from '@rmwc/avatar'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadBox } from '../common/RadBox'
import { RadButton } from '../common/RadButton'
import { RadCards } from '../common/RadCards'
import { RadTextFilter } from '../common/RadTextFilter'
import { RadHeader } from '../common/RadHeader'
import { RadLink } from '../common/RadLink'
import { RadPagination } from '../common/RadPagination'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { formatDateTime } from '../common/utilities'

export function ParticipantList ({ selected }) {
  const pageLength = 20
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [reloadCount, setReloadCount] = useState(0)
  const [currentPageIndex, setCurrentPageIndex] = useState(searchParams.get('page') != null ? parseInt(searchParams.get('page')) : 1)
  const [filteringText, setFilteringText] = useState(searchParams.get('search') ?? '')
  const [searchText, setSearchText] = useState(searchParams.get('search') ?? '')
  const [selectedItems, setSelectedItems] = useState([])
  const { data: participants, count } = useGet(`/api/participant?search=${searchText}&limit=${pageLength}&offset=${(currentPageIndex - 1) * pageLength}`)
  const sendInvitations = usePost('/api/participant/send-invitation', { participantIds: selectedItems.map(x => x.id) }, () => { setReloadCount(reloadCount + 1); setSelectedItems([]) })

  function sendInvitation () {
    sendInvitations()
  }
  if (
    participants != null
  ) {
    return (
      <RadAppLayout
        contentHeader={
          <RadHeader
            variant='h1'
            actions={
              <RadSpaceBetween direction='horizontal' size='xs'>
                <RadButton onClick={() => navigate('/participant/create')}>Create</RadButton>
                <RadButton onClick={sendInvitation} disabled>Send Invitations</RadButton>
              </RadSpaceBetween>
            }
            counter={'(' + count + ')'}
          >
            Participants
          </RadHeader>
        }
        content={
          <RadCards
            selectionType='multi'
            selectedItems={selectedItems}
            onSelectionChange={({ detail }) => setSelectedItems(detail.selectedItems)}
            cardDefinition={{
              header: item => (
                <>
                  <Avatar
                    src={item.photoUrl}
                    size='large'
                    name={`${item.firstName} ${item.lastName}`}
                    referrerPolicy='no-referrer'
                  />
                  <br />
                  <RadLink fontSize='heading-m' href={`/participant/${item.id}`}>{item.lastName}, {item.firstName}</RadLink>
                </>
              ),
              sections: [
                {
                  id: 'organization',
                  header: 'Organization',
                  content: item => item.organization ?? '-'
                },
                {
                  id: 'invitationSentAt',
                  header: 'Invitation Sent',
                  content: item => item.invitationSentAt != null ? formatDateTime(item.invitationSentAt) : '-'
                },
                {
                  id: 'percentComplete',
                  content: item => {
                    let percentComplete = -1
                    let approximately = false
                    if (item.lastMessageText != null) {
                      const regex = /(\d+)% Complete with Interview/
                      const match = item.lastMessageText.match(regex)
                      percentComplete = match ? match[1] : -1
                    }
                    if (item.lastMessageText != null) {
                      const regex = /\[Approximately (\d+)% Complete with Interview/
                      const match = item.lastMessageText.match(regex)
                      if (match) approximately = true
                    }
                    return (<RadBox fontWeight='bold'>{((approximately ? 'Approximately ' : '') + (percentComplete ?? '0') + '% Complete ').replace('-1% Complete', 'Not Started')}</RadBox>)
                  }
                }
              ]
            }}
            cardsPerRow={[
              { cards: 1 },
              { minWidth: 640, cards: 2 },
              { minWidth: 960, cards: 3 },
              { minWidth: 1280, cards: 4 }
            ]}
            items={participants}
            variant='full-page'
            filter={
              <RadTextFilter
                filteringPlaceholder='Search'
                filteringAriaLabel='Search participants'
                filteringText={filteringText}
                onChange={({ detail }) => setFilteringText(detail.filteringText)}
                onDelayedChange={({ detail }) => {
                  setSearchText(detail.filteringText)
                  setCurrentPageIndex(1)
                  searchParams.set('page', 1)
                  if (detail.filteringText) {
                    searchParams.set('search', detail.filteringText)
                  } else {
                    searchParams.delete('search')
                  }
                  setSearchParams(searchParams)
                }}
              />
            }
            pagination={
              <RadPagination
                currentPageIndex={currentPageIndex}
                pagesCount={Math.ceil(count / pageLength)}
                onChange={({ detail }) => {
                  searchParams.set('page', detail.currentPageIndex)
                  setSearchParams(searchParams)
                  setCurrentPageIndex(detail.currentPageIndex)
                }}
                ariaLabels={{
                  nextPageLabel: 'Next page',
                  previousPageLabel: 'Previous page',
                  pageLabel: pageNumber => `Page ${pageNumber} of all pages`
                }}
              />
            }
            empty={
              <RadBox textAlign='center' color='inherit'>
                No matches found.
              </RadBox>
            }
          />
        }
      />
    )
  }
}
