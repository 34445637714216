import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useGet } from '../hooks/useGet'
import { usePost } from '../hooks/usePost'
import { useDelete } from '../hooks/useDelete'
import { useConfirm } from '../hooks/useConfirm'
import { Avatar } from '@rmwc/avatar'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadBox } from '../common/RadBox'
import { RadButton } from '../common/RadButton'
import { RadContainer } from '../common/RadContainer'
import { RadHeader } from '../common/RadHeader'
import { RadLineBreakRenderer } from '../common/RadLineBreakRenderer'
import { RadProgressBar } from '../common/RadProgressBar'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { formatDate, formatDateTime } from '../common/utilities'
import './Survey.scss'

export function Survey () {
  const { uuid } = useParams()
  const [initialized, setInitialized] = useState()
  const [formValues, setFormValues] = useState({})
  const [reloadCounter, setReloadCounter] = useState(0)
  const [percentComplete, setPercentComplete] = useState(0)
  const [approximately, setApproximately] = useState(false)
  const [responses, setResponses] = useState([])
  const { data: participant } = useGet(`/api/message/participant/${uuid}`)
  const { data: messages } = useGet(`/api/message/${uuid}?_${reloadCounter}`, true)
  const go = usePost(`/api/message/go/${uuid}`, {}, (resp) => { }, true)
  const post = usePost(`/api/message/${uuid}`, formValues, (resp) => { setFormValues({}) }, true)
  const confirmDelete = useConfirm('Start over?', 'Start over? This action cannot be undone.', 'Start Over', () => { remove() })
  const remove = useDelete(
    `/api/message/${uuid}`,
    () => {
      setInitialized(false)
      setPercentComplete(0)
      setResponses([])
    })

  useEffect(() => {
    if (formValues.text != null && formValues.text.trim() !== '') { post() }
  }, [formValues])

  useEffect(() => {
    if (initialized !== true && messages != null && messages.length === 0) {
      go()
      setInitialized(true)
    } else if (messages != null && messages.length > 0 && messages[messages.length - 1].direction === 'out') {
      setTimeout(() => { setReloadCounter(reloadCounter + 1) }, 1000)
    }
    document.documentElement.scrollTo({ top: document.documentElement.scrollHeight, behavior: 'smooth' })

    for (const message of messages ?? []) {
      if (message.text != null) {
        const regex = /(\d+)% Complete with Interview/
        const match = message.text.match(regex)
        if (match) {
          if (percentComplete == null || parseInt(match[1]) > percentComplete) {
            setPercentComplete(parseInt(match[1]))
          }
        }
      }
      if (message.text != null) {
        const regex = /\[Approximately/
        const match = message.text.match(regex)
        setApproximately(match != null)
      }
      if (message === messages[messages.length - 1] && message.direction === 'in') {
        const responseRegex = /\[RESPONSE(.*?)\]/g
        const match = message.text.match(responseRegex)
        const responses = (match ?? []).map(x => x.replace('[RESPONSE: ', '').replace(']', '').trim())
        setResponses(responses)
      }
    }
  }, [messages])

  if (participant != null && messages != null) {
    return (
      <RadAppLayout
        breadcrumbs=' '
        navigationHide
        contentHeader={
          <RadHeader
            variant='h1'
            actions={
              <RadSpaceBetween direction='horizontal' size='xs'>
                <RadButton onClick={confirmDelete} disabled={messages.length === 0}>Start Over</RadButton>
              </RadSpaceBetween>
            }
          >
            Technology and Data Advisory Interview
            <RadBox padding={{ top: 'l' }}>
              <RadProgressBar
                label={(approximately ? 'Approximately' : '') + percentComplete + '% Complete with Interview'}
                percent={percentComplete}
              />
            </RadBox>
          </RadHeader>
        }
        content={
          <div className='chat'>
            <RadContainer
              fitHeight
            >

              <div className='messages'>
                <RadSpaceBetween size='l'>
                  {messages.map(message =>
                    <div key={message.id} className={message.direction}>
                      {message.direction === 'in' &&
                        <Avatar
                          src='../mio.png'
                          size='large'
                          name='MIO'
                          referrerPolicy='no-referrer'
                        />}
                      {message.direction === 'out' &&
                        <Avatar
                          size='large'
                          name={`${participant.firstName} ${participant.lastName}`}
                          referrerPolicy='no-referrer'
                        />}
                      <div className='message'><span><RadLineBreakRenderer text={message.text.replace(/\[(.*?)\]/g, '').trim()} /></span></div>
                      <div className='time'>{formatDateTime(message.createdAt).replace(formatDate(Date.now()), '')}</div>
                    </div>
                  )}
                  {(messages.length === 0 || messages[messages.length - 1].direction === 'out') &&
                    <div className='in'>
                      <Avatar
                        src='../mio.png'
                        size='large'
                        name='MIO'
                        referrerPolicy='no-referrer'
                      />
                      <div className='typing'>
                        <div className='typing__dot' />
                        <div className='typing__dot' />
                        <div className='typing__dot' />
                      </div>
                    </div>}
                </RadSpaceBetween>
              </div>
            </RadContainer>
            <Input onEnter={({ detail }) => setFormValues({ ...formValues, direction: 'out', text: detail.value })} />
            {responses.length > 0 &&
              <RadBox padding={{ top: 'xxs' }}>
                <div className='responses'>
                  <span>Suggestions: </span>
                  {responses.map((response, index) =>
                    <button
                      key={index}
                      onClick={() => {
                        setFormValues({ ...formValues, direction: 'out', text: response })
                        setResponses([])
                      }}
                    >
                      {response}
                    </button>
                  )}
                </div>
              </RadBox>}
          </div>
        }
      />
    )
  }
}

function Input ({ initialValue, onEnter, ...rest }) {
  const [value, setValue] = useState(initialValue ?? '')
  return (
    <div className='input'>
      <textarea
        inputMode='text'
        type='text'
        placeholder='Enter message'
        {...rest}
        value={value}
        onChange={(event) => {
          const value = event.target.value
          setValue(value)
          if (value.trim() === '') {
            setValue('')
            onEnter({ detail: { value: '' } })
          }
        }}
        onKeyDown={(x) => {
          if (x.key === 'Enter' && !x.shiftKey) {
            onEnter({ detail: { value } })
            setValue('')
          }
        }}
      />
      <button
        className='material-symbols-outlined'
        onClick={() => {
          if (value.trim() !== '') {
            setValue('')
            onEnter({ detail: { value } })
          }
        }}
      >
        send
      </button>
    </div>
  )
}
