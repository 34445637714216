import React, { createContext, useState, useEffect } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { firebase, auth, ui } from './firebase'
import { SignIn } from './SignIn'
import { RadTopNavigation } from './common/RadTopNavigation'
import { IsLoading } from './common/IsLoading'
import { RadNotFound } from './common/RadNotFound'
import { RadPermissionDenied } from './common/RadPermissionDenied'
import { RadConfirmationModal } from './common/RadConfirmationModal'
import { Dashboard } from './Dashboard'
import { Invite } from './invite/Invite'
import { InviteNotFound } from './invite/InviteNotFound'
import { AgentDetail } from './agent/AgentDetail'
import { AgentEdit } from './agent/AgentEdit'
import { AgentList } from './agent/AgentList'
import { ParticipantDetail } from './participant/ParticipantDetail'
import { ParticipantEdit } from './participant/ParticipantEdit'
import { ParticipantList } from './participant/ParticipantList'
import { PersonDetail } from './person/PersonDetail'
import { PersonEdit } from './person/PersonEdit'
import { PersonList } from './person/PersonList'
import { RoleDetail } from './role/RoleDetail'
import { RoleEdit } from './role/RoleEdit'
import { RoleList } from './role/RoleList'
import { Survey } from './survey/Survey'
import { TagList } from './tag/TagList'
import { TagEdit } from './tag/TagEdit'
import { TagDetail } from './tag/TagDetail'
import { TypeEdit } from './type/TypeEdit'
import { TypeList } from './type/TypeList'
import { UserDetail } from './user/UserDetail'
import { UserEdit } from './user/UserEdit'
import { UserList } from './user/UserList'

import '@cloudscape-design/global-styles/index.css'
import './App.scss'

export const AppContext = createContext(null)

export function App () {
  const [user, setUser] = useState(undefined)
  const [loadingCount, setLoadingCount] = useState(1)
  const [notFound, setNotFound] = useState(false)
  const [permissionDenied, setPermissionDenied] = useState(null)
  const [error, setError] = useState()
  const [navigationOpen, setNavigationOpen] = useState(window.localStorage.getItem('navigationOpen') === 'true')
  const [reloadCounter, setReloadCounter] = useState(0)
  const [confirmModal, setConfirmModal] = useState({ visible: false })

  useEffect(() => {
    window.localStorage.setItem('navigationOpen', navigationOpen)
  }, [navigationOpen])

  useEffect(() => {
    const unregisterAuthObserver = auth.onAuthStateChanged((user) => {
      setUser(user)
      setLoadingCount(count => count - 1)
    })
    return () => unregisterAuthObserver()
  }, [])

  if (user === undefined) {
    return <IsLoading loadingCount={loadingCount} />
  }

  if (window.location.pathname.includes('invite')) {
    return (
      <BrowserRouter>
        <AppContext.Provider value={{
          user,
          loadingCount,
          setLoadingCount,
          notFound,
          setNotFound,
          permissionDenied,
          setPermissionDenied,
          reloadCounter,
          setReloadCounter,
          error,
          setError,
          navigationOpen,
          setNavigationOpen,
          confirmModal,
          setConfirmModal
        }}
        >
          <Routes>
            <Route path='/invite/:uuid' element={<Invite />} />
          </Routes>
          {loadingCount === 0 && notFound && <InviteNotFound />}
          <RadConfirmationModal />
        </AppContext.Provider>
      </BrowserRouter>
    )
  }

  if (window.location.pathname.includes('survey')) {
    return (
      <BrowserRouter>
        <AppContext.Provider value={{
          user,
          loadingCount,
          setLoadingCount,
          notFound,
          setNotFound,
          permissionDenied,
          setPermissionDenied,
          reloadCounter,
          setReloadCounter,
          error,
          setError,
          navigationOpen,
          setNavigationOpen,
          confirmModal,
          setConfirmModal
        }}
        >
          <Routes>
            <Route path='/survey/:uuid' element={<Survey />} />
          </Routes>
          <RadConfirmationModal />
        </AppContext.Provider>
      </BrowserRouter>
    )
  }

  // link to docs for options
  // https://firebase.google.com/docs/auth/web/firebaseui
  if (user === null) {
    ui.start('#firebaseui-auth-container', {
      signInFlow: 'popup',
      signInOptions: [
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        firebase.auth.EmailAuthProvider.PROVIDER_ID
      ],
      callbacks: {
        signInFailure: function (error) {
          console.log(error)
        },
        signInSuccessWithAuthResult: function (result) {
          ui.reset()
          return false
        }
      }
    })
    ui.disableAutoSignIn()
    return <SignIn />
  }

  return (
    <BrowserRouter>
      <AppContext.Provider value={{
        user,
        loadingCount,
        setLoadingCount,
        notFound,
        setNotFound,
        permissionDenied,
        setPermissionDenied,
        reloadCounter,
        setReloadCounter,
        error,
        setError,
        navigationOpen,
        setNavigationOpen,
        confirmModal,
        setConfirmModal
      }}
      >
        <RadTopNavigation />
        <Routes>
          <Route path='/' element={<Dashboard />} />

          <Route path='/agent' element={<AgentList />} />
          <Route path='/agent/:agentId' element={<AgentDetail />} />
          <Route path='/agent/create' element={<AgentEdit />} />
          <Route path='/agent/:agentId/edit' element={<AgentEdit />} />

          <Route path='/participant' element={<ParticipantList />} />
          <Route path='/participant/:participantId' element={<ParticipantDetail />} />
          <Route path='/participant/create' element={<ParticipantEdit />} />
          <Route path='/participant/:participantId/edit' element={<ParticipantEdit />} />

          <Route path='/person' element={<PersonList />} />
          <Route path='/person/:personId' element={<PersonDetail />} />
          <Route path='/person/create' element={<PersonEdit />} />
          <Route path='/person/:personId/edit' element={<PersonEdit />} />

          <Route path='/role' element={<RoleList />} />
          <Route path='/role/create' element={<RoleEdit />} />
          <Route path='/role/:roleId' element={<RoleDetail />} />
          <Route path='/role/:roleId/edit' element={<RoleEdit />} />

          <Route path='/tag' element={<TagList />} />
          <Route path='/tag/create' element={<TagEdit />} />
          <Route path='/tag/:tagId' element={<TagDetail />} />
          <Route path='/tag/:tagId/edit' element={<TagEdit />} />

          <Route path='/type' element={<TypeList />} />
          <Route path='/type/:entity' element={<TypeEdit />} />

          <Route path='/user' element={<UserList />} />
          <Route path='/user/create' element={<UserEdit />} />
          <Route path='/user/:userId' element={<UserDetail />} />
          <Route path='/user/:userId/edit' element={<UserEdit />} />

          <Route path='*' element={<RadNotFound />} />
        </Routes>
        <IsLoading loadingCount={loadingCount} />
        {loadingCount === 0 && notFound && <RadNotFound />}
        {loadingCount === 0 && permissionDenied != null && <RadPermissionDenied message={permissionDenied} />}
        <RadConfirmationModal />
      </AppContext.Provider>
    </BrowserRouter>
  )
}
