import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { useGet } from '../hooks/useGet'
import { useDelete } from '../hooks/useDelete'
import { usePost } from '../hooks/usePost'
import { useConfirm } from '../hooks/useConfirm'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadBox } from '../common/RadBox'
import { RadButton } from '../common/RadButton'
import { RadCheckbox } from '../common/RadCheckbox'
import { RadColumnLayout } from '../common/RadColumnLayout'
import { RadContainer } from '../common/RadContainer'
import { RadDivider } from '../common/RadDivider'
import { RadExpandableSection } from '../common/RadExpandableSection'
import { RadForm } from '../common/RadForm'
import { RadFormField } from '../common/RadFormField'
import { RadHeader } from '../common/RadHeader'
import { RadInput } from '../common/RadInput'
import { RadLineBreakRenderer } from '../common/RadLineBreakRenderer'
import { RadLink } from '../common/RadLink'
import { RadModal } from '../common/RadModal'
import { RadSelect } from '../common/RadSelect'
import { RadShowMore } from '../common/RadShowMore'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadTable } from '../common/RadTable'
import { RadTextFilter } from '../common/RadTextFilter'
import { formatDateTime } from '../common/utilities'
// import { RadTextarea } from '../common/RadTextarea'

export function AgentDetail () {
  const pageLength = 20
  const navigate = useNavigate()
  const { agentId } = useParams()
  const [reloadCount, setReloadCount] = useState(0)
  const [newPerson, setNewPerson] = useState({})
  const [expandInvitations, setExpandInvitations] = useState(false)
  const [showInviteNewModal, setShowInviteNewModal] = useState(false)
  const [showInviteExistingModal, setShowInviteExistingModal] = useState(false)
  const [showSendToModal, setShowSendToModal] = useState(false)
  const [notify, setNotify] = useState(false)
  const [selectedAgent, setSelectedAgent] = useState()
  const [processIndividually, setProcessIndividually] = useState(false)
  const [selectedPeople, setSelectedPeople] = useState([])
  const [selectedInvitations, setSelectedInvitations] = useState([])

  const [expandOutputs, setExpandOutputs] = useState(true)
  const [selectedOutputs, setSelectedOutputs] = useState([])
  const confirmDeleteOutputs = useConfirm('Delete outputs?', 'Delete outputs permanently? This action cannot be undone.', 'Delete', () => { removeOutputs() })
  const removeOutputs = usePost(`/api/agent/${agentId}/delete-outputs`, { outputIds: selectedOutputs.map(x => x.id).join(',') }, () => { setReloadCount(reloadCount + 1); setSelectedInvitations([]) })

  const [filteringText, setFilteringText] = useState('')
  const [searchText, setSearchText] = useState('')
  const [currentPageIndex, setCurrentPageIndex] = useState(1)
  const { data: agent } = useGet(`/api/agent/${agentId}?reloadCount=${reloadCount}`, true)
  const { data: people } = useGet(`/api/person?search=${searchText}&limit=${pageLength}&offset=${(currentPageIndex - 1) * pageLength}`)
  const { data: agentOptions } = useGet('/api/option/agent')
  const confirmDelete = useConfirm('Delete agent?', 'Delete agent permanently? This action cannot be undone.', 'Delete', () => { remove() })
  const remove = useDelete(`/api/agent/${agentId}`, () => { navigate('/agent') })
  const confirmDeleteInvitations = useConfirm('Delete invitations?', 'Delete invitation permanently? This action cannot be undone.', 'Delete', () => { removeInvitations() })
  const removeInvitations = usePost(`/api/agent/${agentId}/delete-invitations`, { invitationIds: selectedInvitations.map(x => x.id).join(',') }, () => { setReloadCount(reloadCount + 1); setSelectedInvitations([]) })
  const sendInvitations = usePost(`/api/agent/${agentId}/send-invitations`, { notify, personIds: selectedPeople.map(x => x.id).join(',') }, () => { setShowInviteExistingModal(false); setReloadCount(reloadCount + 1); setSelectedPeople([]) })
  const sendInvitation = usePost(`/api/agent/${agentId}/send-invitation`, newPerson, () => { setShowInviteNewModal(false); setReloadCount(reloadCount + 1); setNewPerson({}) })
  const sendToAgent = usePost(
    `/api/agent/${selectedAgent}/import-data`,
    { invitationIds: selectedInvitations.map(x => x.id), outputIds: selectedOutputs.map(x => x.id), processIndividually },
    (response) => { setShowSendToModal(false) }
    // (error) => { setAgentResponse({ text: 'An error occurred.' }) }
  )

  let environmentURL
  switch (window.location.hostname.split('.')[0]) {
    case 'chat':
      environmentURL = 'https://chat.asem.io'
      break
    case 'uat-qreihuyxgq-uc':
      environmentURL = 'https://uat-qreihuyxgq-uc.a.run.app'
      break
    case 'qa-qreihuyxgq-uc':
      environmentURL = 'https://qa-qreihuyxgq-uc.a.run.app'
      break
    default:
      environmentURL = 'http://localhost:3000'
  }

  const fields = [
    { name: '[AGENT NAME]', value: agent?.name },
    { name: '[LINK]', value: `${environmentURL}/invite/${uuidv4()}` },
    { name: '[PERSON FIRST NAME]', value: 'John' },
    { name: '[PERSON LAST NAME]', value: 'Doe' },
    { name: '[PERSON ORGANIZATION]', value: 'Google' }
  ]

  function getPreview (text) {
    let outputs = text
    for (const field of fields) {
      outputs = outputs.replaceAll(field.name, field.value)
    }
    return outputs
  }

  function send () {
    sendToAgent()
  }

  if (
    agent != null &&
    agentOptions != null &&
    people != null
  ) {
    return (
      <RadAppLayout
        name={agent.name}
        contentHeader={
          <RadHeader
            variant='h1'
            actions={
              <RadSpaceBetween direction='horizontal' size='xs'>
                <RadButton onClick={() => navigate('edit')}>Edit</RadButton>
                <RadButton onClick={() => navigate(`/agent/create?sourceId=${agentId}`)}>Duplicate</RadButton>
                <RadButton onClick={confirmDelete}>Delete</RadButton>
                <RadButton onClick={() => setShowInviteNewModal(true)}>Invite New Person</RadButton>
                <RadButton onClick={() => setShowInviteExistingModal(true)}>Invite Existing Person(s)</RadButton>
              </RadSpaceBetween>
            }
            description={agent.description}
          >
            {agent.name}
          </RadHeader>
        }
        content={
          <>
            <RadSpaceBetween size='l'>
              <RadContainer
                header={
                  <RadHeader variant='h2'>
                    Details
                  </RadHeader>
                }
              >
                <RadSpaceBetween size='l'>
                  <RadColumnLayout columns={4} borders='vertical'>
                    <div>
                      <RadBox variant='awsui-key-label'>
                        Type
                      </RadBox>
                      <div>{agent.type.name}</div>
                    </div>
                    <div>
                      <RadBox variant='awsui-key-label'>
                        Model
                      </RadBox>
                      <div>{agent.model}</div>
                    </div>
                    <div>
                      <RadBox variant='awsui-key-label'>
                        Temperature
                      </RadBox>
                      <div>{agent.temperature}</div>
                    </div>
                    <div>
                      <RadBox variant='awsui-key-label'>
                        Maximum Length
                      </RadBox>
                      <div>{agent.maximumLength ?? '-'}</div>
                    </div>
                    <div>
                      <RadBox variant='awsui-key-label'>
                        Frequency Penalty
                      </RadBox>
                      <div>{agent.frequencyPenalty}</div>
                    </div>
                    <div>
                      <RadBox variant='awsui-key-label'>
                        Presence Penalty
                      </RadBox>
                      <div>{agent.presencePenalty}</div>
                    </div>
                    <div>
                      <RadBox variant='awsui-key-label'>
                        Stop
                      </RadBox>
                      <div>{agent.stop ?? '-'}</div>
                    </div>
                    <div>
                      <RadBox variant='awsui-key-label'>
                        Show Progress Bar
                      </RadBox>
                      <div>{agent.showProgressBar === true ? 'Yes' : 'No'}</div>
                    </div>
                    <div>
                      <RadBox variant='awsui-key-label'>Tags</RadBox>
                      <div>{agent.tags.length > 0 ? agent.tags.map((x) => x.tag.name).join(', ') : '-'}</div>
                    </div>
                  </RadColumnLayout>
                </RadSpaceBetween>
              </RadContainer>
              <RadExpandableSection
                headerText='System Message'
                variant='container'
              >
                <RadLineBreakRenderer text={agent.systemMessage} />
              </RadExpandableSection>
              <RadExpandableSection
                headerText='Document'
                variant='container'
              >
                <RadLineBreakRenderer text={agent.document ?? ''} />
              </RadExpandableSection>
              {agent.type.name === 'Chatbot' &&
                <>
                  <RadExpandableSection
                    variant='container'
                    expanded={expandInvitations}
                    onChange={({ detail }) => setExpandInvitations(detail.expanded)}
                    headerText='Invitations'
                    headerCounter={'(' + agent.invitations.length + ')'}
                    headerActions={
                      <RadSpaceBetween direction='horizontal' size='xs'>
                        <RadButton onClick={() => setShowSendToModal(true)} disabled={selectedInvitations.length === 0}>Send to Agent</RadButton>
                        <RadButton onClick={confirmDeleteInvitations} disabled={selectedInvitations.length === 0}>Delete</RadButton>
                      </RadSpaceBetween>
                }
                  >
                    <RadTable
                      onSelectionChange={({ detail }) => setSelectedInvitations(detail.selectedItems)}
                      selectedItems={selectedInvitations}
                      selectionType='multi'
                      variant='embedded'
                      trackBy='id'
                      items={agent.invitations}
                      columnDefinitions={[
                        {
                          id: 'name',
                          header: 'Name',
                          cell: e => <RadLink href={`/person/${e.person.id}`}>{e.person.fullName}</RadLink>
                        },
                        {
                          id: 'messageCount',
                          header: 'Messages',
                          cell: e => e.messageCount
                        },
                        {
                          id: 'link',
                          header: 'Link',
                          cell: e => <RadLink href={`${environmentURL}/invite/${e.uuid}`} target='_blank'>{`${environmentURL}/invite/${e.uuid}`}</RadLink>
                        },
                        {
                          id: 'createdAt',
                          header: 'Created At',
                          cell: e => formatDateTime(e.createdAt)
                        }
                      ]}
                  // filter={
                  //   <RadTextFilter
                  //     filteringPlaceholder='Search'
                  //     filteringAriaLabel='Search persons'
                  //     filteringText={filteringText}
                  //     onChange={({ detail }) => setFilteringText(detail.filteringText)}
                  //     onDelayedChange={({ detail }) => {
                  //       setSearchText(detail.filteringText)
                  //       setCurrentPageIndex(1)
                  //     }}
                  //   />
                  // }
                      empty={
                        <RadBox textAlign='center' padding={{ top: 'l' }}>
                          <RadBox>
                            No matches found.
                          </RadBox>
                        </RadBox>
                  }
                    />
                  </RadExpandableSection>
                  <RadExpandableSection
                    headerText='Invitation Preview'
                    variant='container'
                  >
                    <RadSpaceBetween size='xxs'>
                      <div>
                        {getPreview(agent.invitationSubject)}
                      </div>
                      <RadDivider />
                      <div dangerouslySetInnerHTML={{ __html: getPreview(agent.invitationBody) }} />
                    </RadSpaceBetween>
                  </RadExpandableSection>
                </>}
              {agent.type.name === 'Processor' &&
                <RadExpandableSection
                  variant='container'
                  expanded={expandOutputs}
                  onChange={({ detail }) => setExpandOutputs(detail.expanded)}
                  headerText='Outputs'
                  headerCounter={'(' + agent.outputs.length + ')'}
                  headerActions={
                    <RadSpaceBetween direction='horizontal' size='xs'>
                      <RadButton onClick={() => setShowSendToModal(true)} disabled={selectedOutputs.length === 0}>Send to Agent</RadButton>
                      <RadButton onClick={confirmDeleteOutputs} disabled={selectedOutputs.length === 0}>Delete</RadButton>
                    </RadSpaceBetween>
                }
                >
                  <RadTable
                    wrapLines
                    onSelectionChange={({ detail }) => setSelectedOutputs(detail.selectedItems)}
                    selectedItems={selectedOutputs}
                    selectionType='multi'
                    variant='embedded'
                    trackBy='id'
                    items={agent.outputs}
                    columnDefinitions={[
                      {
                        id: 'name',
                        header: 'Description',
                        minWidth: '150px',
                        cell: e => <RadLineBreakRenderer text={e.description} />
                      },
                      // {
                      //   id: 'description',
                      //   header: 'Description',
                      //   minWidth: '200px',
                      //   cell: e => <RadLineBreakRenderer text={e.description} />
                      // },
                      {
                        id: 'text',
                        header: 'Text',
                        cell: e => <RadShowMore text={e.text} />
                      },
                      {
                        id: 'createdAt',
                        header: 'Created',
                        minWidth: '110px',
                        // maxWidth: '100px',
                        cell: e => formatDateTime(e.createdAt)
                      }
                    ]}
                    empty={
                      <RadBox textAlign='center' padding={{ top: 'l' }}>
                        <RadBox>
                          No outputs found.
                        </RadBox>
                      </RadBox>
                  }
                  />
                </RadExpandableSection>}
            </RadSpaceBetween>
            <RadModal
              onDismiss={() => setShowInviteExistingModal(false)}
              visible={showInviteExistingModal}
              footer={
                <>
                  <RadBox float='left' padding={{ top: 'xxs' }}>
                    <RadCheckbox
                      checked={notify}
                      onChange={({ detail }) => setNotify(detail.checked)}
                    >
                      Send Notification
                    </RadCheckbox>
                  </RadBox>
                  <RadBox float='right'>
                    <RadSpaceBetween direction='horizontal' size='xs'>
                      <RadButton
                        onClick={() => setShowInviteExistingModal(false)}
                        variant='link'
                      >
                        Cancel
                      </RadButton>
                      <RadButton
                        onClick={() => sendInvitations()}
                        variant='primary'
                        disabled={selectedPeople.length === 0}
                      >
                        Invite {selectedPeople.length} {selectedPeople.length > 1 ? ' People' : ' Person'}
                      </RadButton>
                    </RadSpaceBetween>
                  </RadBox>
                </>
              }
              header='Invite'
            >
              <RadTable
                onSelectionChange={({ detail }) => setSelectedPeople(detail.selectedItems)}
                selectedItems={selectedPeople}
                selectionType='multi'
                variant='embedded'
                trackBy='id'
                items={people}
                columnDefinitions={[
                  {
                    id: 'name',
                    header: 'Name',
                    cell: e => e.fullName
                  },
                  {
                    id: 'email',
                    header: 'Email',
                    cell: e => e.email
                  }
                ]}
                filter={
                  <RadTextFilter
                    filteringPlaceholder='Search'
                    filteringAriaLabel='Search persons'
                    filteringText={filteringText}
                    onChange={({ detail }) => setFilteringText(detail.filteringText)}
                    onDelayedChange={({ detail }) => {
                      setSearchText(detail.filteringText)
                      setCurrentPageIndex(1)
                    }}
                  />
                }
                empty={
                  <RadBox textAlign='center' padding={{ top: 'l' }}>
                    <RadBox>
                      No matches found.
                    </RadBox>
                  </RadBox>
                }
              />
            </RadModal>
            <RadModal
              onDismiss={() => setShowInviteNewModal(false)}
              visible={showInviteNewModal}
              footer={
                <>
                  <RadBox float='left' padding={{ top: 'xxs' }}>
                    <RadCheckbox
                      checked={newPerson.notify}
                      onChange={({ detail }) => setNewPerson({ ...newPerson, notify: detail.checked })}
                    >
                      Send Notification
                    </RadCheckbox>
                  </RadBox>
                  <RadBox float='right'>
                    <RadSpaceBetween direction='horizontal' size='xs'>
                      <RadButton
                        onClick={() => {
                          setShowInviteNewModal(false)
                          setNewPerson({})
                        }}
                        variant='link'
                      >
                        Cancel
                      </RadButton>
                      <RadButton
                        onClick={() => { sendInvitation() }}
                        variant='primary'
                      >
                        Invite
                      </RadButton>
                    </RadSpaceBetween>
                  </RadBox>
                </>
              }
              header='Invite'
            >
              <form onSubmit={e => e.preventDefault()}>
                <RadForm>
                  <RadSpaceBetween size='l'>
                    <RadSpaceBetween size='l'>
                      <RadFormField label='First name *' field='firstName'>
                        <RadInput
                          placeholder='Enter first name'
                          ariaRequired
                          value={newPerson.firstName}
                          onChange={({ detail }) => setNewPerson({ ...newPerson, firstName: detail.value })}
                        />
                      </RadFormField>
                      <RadFormField label='Last name *' field='lastName'>
                        <RadInput
                          placeholder='Enter last name'
                          ariaRequired
                          value={newPerson.lastName}
                          onChange={({ detail }) => setNewPerson({ ...newPerson, lastName: detail.value })}
                        />
                      </RadFormField>
                      <RadFormField label='Email *' field='email'>
                        <RadInput
                          placeholder='Enter email'
                          ariaRequired
                          value={newPerson.email}
                          onChange={({ detail }) => setNewPerson({ ...newPerson, email: detail.value })}
                        />
                      </RadFormField>
                      <RadFormField label='Organization' field='organization'>
                        <RadInput
                          placeholder='Enter organization'
                          ariaRequired
                          value={newPerson.organization}
                          onChange={({ detail }) => setNewPerson({ ...newPerson, organization: detail.value })}
                        />
                      </RadFormField>
                    </RadSpaceBetween>
                  </RadSpaceBetween>
                </RadForm>
              </form>
            </RadModal>
            <RadModal
              onDismiss={() => {
                setShowSendToModal(false)
              }}
              visible={showSendToModal}
              footer={
                <RadBox float='right'>
                  <RadSpaceBetween direction='horizontal' size='xs'>
                    <RadButton
                      onClick={() => { setShowSendToModal(false) }}
                      variant='link'
                    >
                      Cancel
                    </RadButton>
                    <RadButton
                      onClick={() => { send() }}
                      variant='primary'
                      disabled={selectedAgent == null}
                    >
                      Send
                    </RadButton>
                  </RadSpaceBetween>
                </RadBox>
              }
              header='Send to Agent'
            >
              <RadSpaceBetween size='l'>
                <RadSpaceBetween size='l'>
                  <RadSelect
                    selectedOption={selectedAgent ? agentOptions.find(x => x.value === selectedAgent.toString()) : { value: '', label: '-' }}
                    onChange={({ detail }) => {
                      if (detail.selectedOption.value !== '') {
                        setSelectedAgent(parseInt(detail.selectedOption.value))
                      } else {
                        setSelectedAgent(null)
                      }
                    }}
                    options={agentOptions.filter(x => x.type !== 'Chatbot')}
                    selectedAriaLabel='Selected'
                    empty='No matches found'
                  />
                  <RadFormField label='Process Individually' field='processIndividually'>
                    <RadCheckbox
                      checked={processIndividually}
                      onChange={({ detail }) => {
                        setProcessIndividually(detail.checked)
                      }}
                    />
                  </RadFormField>
                </RadSpaceBetween>
              </RadSpaceBetween>
            </RadModal>
            {/* <RadModal
              header={agentResponse?.agent?.name + ' Output'}
              onDismiss={() => {
                setAgentResponse(null)
              }}
              visible={agentResponse != null}
              footer={
                <RadBox float='right'>
                  <RadSpaceBetween direction='horizontal' size='xs'>
                    <RadButton
                      onClick={() => { setAgentResponse(null) }}
                      variant='link'
                    >
                      Dismiss
                    </RadButton>
                    <RadButton
                      onClick={() => { saveOutput() }}
                      variant='primary'
                      disabled={agentResponse?.name == null || agentResponse?.description == null || agentResponse?.text == null}
                    >
                      Save and Dismiss
                    </RadButton>
                  </RadSpaceBetween>
                </RadBox>
              }
            >
              <RadSpaceBetween size='l'>
                <RadFormField label='Name *' field='name'>
                  <RadInput
                    placeholder='Enter name'
                    ariaRequired
                    value={agentResponse?.name}
                    onChange={({ detail }) => setAgentResponse({ ...agentResponse, name: detail.value })}
                  />
                </RadFormField>
                <RadFormField label='Description *' field='description'>
                  <RadTextarea
                    placeholder='Enter description'
                    ariaRequired
                    value={agentResponse?.description}
                    onChange={({ detail }) => setAgentResponse({ ...agentResponse, description: detail.value })}
                  />
                </RadFormField>
                <RadFormField label='Text *' field='text'>
                  <RadTextarea
                    rows={10}
                    placeholder='Enter text'
                    ariaRequired
                    value={agentResponse?.text}
                    onChange={({ detail }) => setAgentResponse({ ...agentResponse, text: detail.value })}
                  />
                </RadFormField>
              </RadSpaceBetween>
            </RadModal> */}
          </>
        }
      />
    )
  }
}
