import { auth } from '../firebase'
import { RadBox } from '../common/RadBox'
import { RadBadge } from '../common/RadBadge'
import TopNavigation from '@cloudscape-design/components/top-navigation'

// https://cloudscape.design/components/top-navigation
export function RadTopNavigation () {
  const root = window.location.hostname.split('.')[0]
  let search
  switch (root) {
    case 'qa':
    case 'uat':
      search = <RadBox textAlign='center'><RadBadge color='green'>{root.toUpperCase()}</RadBadge></RadBox>
      break
    default:
  }

  return (
    <TopNavigation
      identity={{
        href: '/',
        title: 'Chatbot'
      }}
      utilities={[
        {
          type: 'menu-dropdown',
          text: auth.currentUser?.displayName,
          description: auth.currentUser?.email,
          iconName: 'user-profile',
          onItemClick: event => {
            switch (event.detail.id) {
              case 'signout':
                auth.signOut()
                break
              default:
                console.log(`${event.detail.id} not implemented`)
            }
          },
          items: [
            { id: 'signout', text: 'Sign out' }
          ]
        }
      ]}
      i18nStrings={{
        overflowMenuTriggerText: 'More',
        overflowMenuTitleText: 'All',
        overflowMenuBackIconAriaLabel: 'Back',
        overflowMenuDismissIconAriaLabel: 'Close menu'
      }}
      search={search}
    />
  )
}
